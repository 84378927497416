/**
 * Return config payslip each position
 *
 * @param {string | Function(row)} key - return value of data.
 * @param {string | Function(index)} title - Title of data.
 * @param {'currency' | '%'} prefix - change prefix value.
 * @param {boolean} extraTime - change prefix value.
 * @param {boolean} logsArray - render multiple rows of log based on key.
 *
 */
//Head office's payslip based on current account type and log account type
export const headOfficeType = {
  logAccountType: new Set(['bod', 'teamleader', 'nhân viên', 'v2_vp_tong']),
  userAccountType: new Set(['bod', 'teamleader', 'nhân viên','nhan-vien']),
};
//GDVP's payslip based on current account type and log account type
export const gdvpType = {
  logAccountType: new Set(['giám đốc văn phòng', 'v2_gdvp']),
  userAccountType: new Set(['gdvp', 'gdvp tt', 'gđvp']),
};
//GDKV's payslip based on current account type and log account type
export const gdkvType = {
  logAccountType: new Set(['gdkv', 'gdkv tt', 'gđkv', 'gđkv tt', 'v2_gdkv']),
  userAccountType: new Set(['gdkv', 'gdkv tt', 'gđkv', 'gđkv tt']),
};
//QLHV's payslip based on current account type and log account type
export const qlhvType = {
  logAccountType: new Set(['qlhv', 'center_assign', 'Quản Lý Học Viên', 'quản lý học viên']),
  userAccountType: new Set(['qlhv', 'center_assign','quan-ly-hoc-vien','quan-ly-hoc-vien']),
};

export const hckvType = {
  logAccountType: new Set(['hckv']),
  userAccountType: new Set(['hckv']),
};

//Sale Intern's payslip based on current account type and log account type
export const saleInternType = {
  logAccountType: new Set(['sales intern', 'sale intern', 'sales thử việc']),
  userAccountType: new Set(['sales-intern', 'sales intern', 'quan-ly-hoc-vien']),
};
//Sale Member's payslip based on current account type and log account type
export const saleMemberType = {
  logAccountType: new Set(['sale member', 'sales']),
  userAccountType: new Set(['sale-member', 'sale member', 'sales']),
};
//Sale Expert's payslip based on current account type and log account type
export const saleExpertType = {
  logAccountType: new Set(['sale expert', 'sales-expert', 'sales expert']),
  userAccountType: new Set(['sale-expert', 'sales-expert']),
};
//Sale Leader's payslip based on current account type and log account type
export const saleLeaderType = {
  logAccountType: new Set(['sales leader', 'sale-leader']),
  userAccountType: new Set(['sale', 'sales-leader', 'sale-leader']),
};
export const saleLeaderCenterType = {
  logAccountType: new Set(['v2_sl_trung_tam']),
  userAccountType: new Set(['sale', 'sales-leader', 'sale-leader']),
};
export const saleLeaderProvinceType = {
  logAccountType: new Set(['v2_sl_tinh']),
  userAccountType: new Set(['sale', 'sales-leader', 'sale-leader']),
};
export const saleLuongCungType = {
  logAccountType: new Set(['v2_sale_luong_cung']),
  userAccountType: new Set(['sale', 'sales-leader', 'sale-leader']),
};

export const saleLuongMemType = {
  logAccountType: new Set(['v2_sale_luong_mem']),
  userAccountType: new Set(['sale', 'sales-leader', 'sale-leader']),
};

export const DETAIL_GDKV = 'gdkv_chi_tiet';
export const DETAIL_GDVP = 'gdvp_chi_tiet';
export const DETAIL_TINH = 'tinh_chi_tiet';
export const DETAIL_TRUNG_TAM = 'trung_tam_chi_tiet';

export const CATEGORY_THU_NHAP_RSM = 'thu_nhap_rsm';
export const CATEGORY_DOANH_SO_DOI_VP = 'doanh_so_doi_van_phong';
export const CATEGORY_DOANH_SO_DOI = 'doanh_so_doi';
export const CATEGORY_TONG_DOANH_SO = 'tong_doanh_so';
export const CATEGORY_DOANH_SO_KEY = 'doanh_so_key';
export const THUONG_TRAI_PHIEU_TICH_LUY = 'thuong_trai_phieu_tich_luy';
export const CATEGORY_PHAT = 'phat';
export const CATEGORY_TRU_KHAC = 'tru_khac';

export const CONVERT_CATEGORY_TO_KEY = {
  [CATEGORY_THU_NHAP_RSM] : "Thu nhập RSM",
  [CATEGORY_PHAT] : "Phạt",
  [CATEGORY_TRU_KHAC] : "Trừ khác"
}
export const DANG_TICH_LUY = 0;
export const CO_HIEU_LUC = 1;
export const DUOC_CHUYEN_NHUONG_BAN = 2;
export const DA_CHUYEN_NHUONG_BAN = 3;
export const NAME_STATUS_BOND = {
  0: 'Đang tích lũy',
  1: 'Có hiệu lực',
  2: 'Được chuyển nhượng/ bán',
  3: 'Đã chuyển nhượng/ bán',
}
